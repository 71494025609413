@import url("date-picker.css");
@import url("square.css");
@import url("color-picker.css");
/* Imports */
@import "froala.css";
@import "quill.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --gf-default-accent-color: #b91c1c;
}

/* Site-wide overrides */

html {
  height: 100%;
}

a.linkified {
  text-decoration: underline;
  color: #8c008c;
}

body {
  height: 100%;
}

.list ol {
  list-style-type: decimal;
  margin-left: 1rem;
}

.list ul {
  list-style-type: disc;
  margin-left: 1rem;
}

/*
 * Page Layout
 */

/* Container that contains header, main, and footer */
.gf-page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
  justify-content: space-between;
}

/*
 * Site Areas
 */

.gf-page {
  height: 100%;
}

/*
 * Text
 */

.running-text {
  -ms-word-break: normal;
  word-break: break-word;
  word-wrap: break-word;
}

.user-text p {
  margin-bottom: 1em;
}

/*
 * Content management controls
 */

.selecting-for-edit .EditableSection {
  position: relative;
}

.selection-area {
  display: block;
  fill: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.selection-area rect {
  fill: none;
  stroke: #000;
  stroke-width: 0.125rem;
  vector-effect: non-scaling-stroke;
  shape-rendering: geometricPrecision;
}

.selection-area.dashes rect {
  stroke: #fff;
  stroke-dasharray: 4px;
  stroke-dashoffset: 8px;
  animation: stroke 0.2s linear infinite;
}

.selection-area.thicker rect {
  stroke-width: 0.325rem;
}

.selection-area.top {
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
}

.selection-area.top rect {
  opacity: 0;
}
.selection-area.top rect.rect-hover {
  fill: pink;
  opacity: 0.5;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

/*
 * Page layout
 */

.header-gradient {
  background-image: linear-gradient(to right, white, #780050);
}

.logo {
  font-size: 100px;
  line-height: 1em;
  text-align: center;
}
.chapter {
  font-size: 54px;
  line-height: 0px;
  text-align: right;
  letter-spacing: -2px;
  margin-top: -20px;
  margin-bottom: 40px;
  margin-right: 18px;
}

/* Next */

#__next {
  min-height: 100vh;
}

/* Stylized basic elements */

.a {
  color: #b529b4;
  cursor: pointer;
}

.a:hover {
  color: red;
}

.gf-area-admin .a {
  color: #026340;
  cursor: pointer;
}

.gf-area-admin .a:hover {
  color: #007e4f;
  text-decoration: underline;
}

.h1 {
  font-weight: 700;
  font-size: 1.25rem;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.h2 {
  font-weight: 700;
  font-size: 1.125rem;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.h3 {
  font-weight: 700;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ul {
  list-style-type: disc;
}

.ol {
  list-style-type: decimal;
}

.ul li {
  margin-left: 2em;
}

.ol li {
  margin-left: 2em;
}

/* For what we inject from Content Management */

.external-content a {
  text-decoration: underline;
}

.external-content .a:hover {
  text-decoration: underline;
}

.external-content .a.LinkButton:hover {
  text-decoration: none;
}

.external-content h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.external-content h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.external-content h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.external-content h4 {
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.external-content h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.external-content h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.external-content p {
  display: block;
  font-size: 1em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.external-content ul {
  list-style-type: disc;
}

.external-content ol {
  list-style-type: decimal;
}

.external-content li {
  margin-left: 2em;
}

.external-content blockquote {
  padding-left: 1em;
  border-left: 4px solid #ccc;
}

/* Burger Menu */

.bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: darkgray;
  margin-bottom: 4px;
  transition: 0.4s;
}

.burger-menu.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 4px);
  transform: rotate(-45deg) translate(-4px, 4px);
}

.burger-menu.open .bar2 {
  opacity: 0;
}

.burger-menu.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

/* Calendar Arrows */
.calendar-arrow-wrapper.arrow-left {
  transform: rotate(180deg);
}

.icon-arrow {
  fill: #606f7b;
}

.icon-pink .icon-arrow {
  fill: #b529b4;
}

.month-increment:hover .icon-arrow {
  fill: #b529b4;
}

.month-increment:hover.disabled .icon-arrow {
  fill: #606f7b;
}

/* Utility Styles */

.expandable-text {
  max-height: 120px;
  position: relative;
  overflow: hidden;
}
.expandable-text .read-more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;

  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 100)
  );
}
.expandable-text-read-more {
  margin-top: -1em;
  position: relative;
}

/* Home Page layout */

.home-page-side-nav {
  min-width: 14rem;
}

.home-page-side-nav h3 {
  margin-top: 0;
}
.home-page-side-nav section {
  margin-bottom: 1em;
}

/* Forms */

label.field-label {
  display: block;
  margin-bottom: 5px;
}

.form-component {
  display: block;
  padding: 0.75rem;
  width: 100%;
  border-radius: 0.5rem;
}

.long-event-description p {
  transition: all 1.5 ease;
}

/* Events */

.public-events img {
  min-width: 100%;
}

/* Event Details */

.logistics-icon {
  display: flex;
  justify-content: flex-end;
}

.logistics-map {
  height: 300px;
}

.icon {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.icon-sm {
  width: 10px;
  height: 10px;
}

/* Event summary */

.event-list-item {
  display: grid;
  grid-template-areas: "picture summary";
  grid-template-columns: 3fr 5fr;
}

.event-canceled p,
.event-canceled h3,
.event-canceled img {
  color: grey;
  opacity: 0.5;
}

.event-list-image {
  grid-area: picture;
}

.event-list-image img {
  object-fit: cover;
}

/* Event Submission */

.accessible-ticket-pricing .data-cell {
  text-align: right;
}
.accessible-ticket-pricing .description {
  padding-bottom: 1em;
}
.accessible-ticket-pricing .percentage {
  /* border-width: 0 1px 0 1px;
  border-color: #aaa;
  border-style: solid; */
  padding-right: 1em;
  background-color: #eaeaea;
}

/* Member pages */

.breadcrumb-symbol {
  margin-right: 15px;
  line-height: 1;
  display: inline-block;
  vertical-align: 9px;
}

.breadcrumb-text {
  line-height: 1;
  display: inline-block;
}

input.message-compose:focus {
  outline: none;
}

/* Input fields */
.disabled,
input:disabled {
  background-color: #f1f1f1;
}

/* Dropdown Result Menu */
.dropdown-result-menu {
  z-index: 9999;
}
