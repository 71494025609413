div.react-datepicker {
  font-family: Montserrat;
}

div.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
}
div.react-datepicker__input-container {
  display: flex;
  align-items: center;
  height: 100%;
}
div.react-datepicker__input-container .react-datepicker__calendar-icon {
  margin-left: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}
div.react-datepicker__input-container input {
  height: 100%;
  width: 100%;
  background-color: transparent;
  padding-left: 3rem;
}
