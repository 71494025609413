.fr-wrapper ol {
  list-style-type: decimal;
  margin-left: 1rem;
}

.fr-wrapper ul {
  list-style-type: disc;
  margin-left: 1rem;
}


.fr-wrapper a {
  text-decoration: underline;
  color: blue;
}

.fr-wrapper h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.fr-wrapper h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.fr-wrapper h3 {
  font-size: 1.17rem;
  font-weight: bold;
  margin-top: 1.17rem;
  margin-bottom: 1.17rem;
}

.fr-wrapper h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.fr-wrapper h5 {
  font-size: .83rem;
  font-weight: bold;
  margin-top: .83rem;
  margin-bottom: .83rem;
}

.fr-wrapper h6 {
  font-size: .67rem;
  font-weight: bold;
  margin-top: .67rem;
  margin-bottom: .67rem;
}

.fr-wrapper p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
