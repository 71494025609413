.gf-quill.quill {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.gf-quill .ql-toolbar.ql-snow {
  border: 0px;
  background-color: #eee;
}

.gf-quill .ql-editor {
  background-color: white;
  overflow-y: scroll;
  max-height: 40rem;
  border-radius: 0.5rem;
}

.gf-quill .ql-container.ql-snow {
  font-size: 16px;
  border: 0px;
}

.gf-quill .ql-container ol {
  list-style-type: decimal;
  margin-left: 1rem;
  padding-left: 0;
}

.gf-quill .ql-container ul {
  list-style-type: disc;
  margin-left: 1rem;
  padding-left: 0;
}

.gf-quill .ql-container a {
  text-decoration: underline;
  color: blue;
}

.gf-quill .ql-container h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
}

.gf-quill .ql-container h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
}

.gf-quill .ql-container h3 {
  font-size: 1.17rem;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
}

.gf-quill .ql-container h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
}

.gf-quill .ql-container h5 {
  font-size: 0.83rem;
  font-weight: bold;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
}

.gf-quill .ql-container h6 {
  font-size: 0.67rem;
  font-weight: bold;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
}

.gf-quill .ql-container p {
  /* margin-top: 1rem;
  margin-bottom: 1rem; */
  font-size: 1em;
  margin-block-start: 1em;
  margin-block-end: 1em;
}
